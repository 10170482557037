<template>
  <v-dialog v-model="dialog" persistent width="1000px" style="z-index: 999">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        v-if="type === 'textButton'"
        style="color: #f0f2f4"
        elevation="0"
        color="#039BE5"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("accountDetails.contactSalesTeam") }}
      </v-btn>
      <v-btn
        v-if="type === 'dialogButton'"
        style="color: #f0f2f4"
        elevation="0"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("accountDetails.contact") }}
      </v-btn>
      <v-btn
        v-if="type != 'textButton' && type != 'dialogButton'"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :title="$t('contactForm.support/contactFormular')" large
          >mdi-chat-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="mt-5 ml-1 text-h5 grey--text">{{
          $t("contactForm.contactUs")
        }}</span>
      </v-card-title>
      <v-card-text class="mb-1 pb-1">
        <template>
          <v-row>
            <v-col cols="8">
              {{
                $t("contactForm.contactInfo", { appName: $appConfig.appName })
              }}
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="6">
              <v-select
                v-model="selectedTopic"
                :items="topics"
                :label="$t('contactForm.topic')"
              >
              </v-select>
            </v-col>
            <v-spacer />
          </v-row>
          <v-row class="pb-0">
            <v-col class="mb-0 pb-0">
              <v-textarea
                v-model="message"
                outlined
                color="teal"
                :label="$t('contactForm.yourMessage')"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-row>
          <v-col class="mb-3" cols="auto">
            <span class="grey--text">
              {{ $t("contactForm.orJustEmailUs") }}</span
            >
            <a
              v-if="$appConfig.keycloakClient === 'xylem'"
              href="mailto:eoapp@xylem.com, xylem@eomap.de"
              >eoapp@xylem.com</a
            >
            <a v-else href="mailto:wq@eomap.de">wq@eomap.de</a>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="closeDialog">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            depressed
            class="mr-5 ml-5"
            color="primary"
            :disabled="email == '' || message == '' || selectedTopic == ''"
            right
            @click="sendMessage"
          >
            {{ $t("contactForm.send") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import creditMixin from "@/core/mixins/credit.mixin";

export default {
  name: "contactPopup",
  mixins: [creditMixin],
  props: {
    userInfo: Array,
    type: String
  },
  data() {
    return {
      dialog: false,
      email: "",
      message: "",
      selectedTopic: "",
      allInputs: false,
      topics: [
        this.$t("contactForm.monitoring"),
        this.$t("contactForm.download"),
        this.$t("contactForm.insituData"),
        this.$t("contactForm.orderData"),
        this.$t("contactForm.upload"),
        this.$t("contactForm.credits"),
        this.$t("contactForm.otherTopic")
      ]
    };
  },
  methods: {
    async sendMessage() {
      await this.postMessage(
        this.userInfo[0],
        this.$appConfig.keycloakClient,
        this.selectedTopic,
        this.message
      );
      this.closeDialog();
    },
    deleteEntries() {
      this.message = "";
      this.selectedTopic = "";
    },
    closeDialog() {
      this.deleteEntries();
      this.dialog = false;
    }
  },
  created() {
    this.email = this.userInfo[0];
    this.name = this.userInfo[1];
  }
};
</script>

<style></style>
